import { useState } from 'react';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { Link, useSearchParams } from 'react-router-dom';
import useSearch from '../../hooks/useSearch';
import CustomerCommonLayout from '../../components/Layout/CustomerCommonLayout';
import styles from '../../styles/styles';
import SingleShopProducts from '../../components/Common/SingleShopProducts';

const SearchPage = () => {
  const { search, result, updateSearch } = useSearch();
  const [searchParams] = useSearchParams();
  const productToSearch = searchParams.get('product');
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchInput = (e) => {
    const term = e.target.value || '';
    setSearchTerm(term);
  };
  const handleSearchChange = () => {
    // const filteredProducts = allProducts && allProducts.filter((product) => product.name.toLowerCase().includes(term.toLowerCase()));
    // setSearchData(filteredProducts);
    // updateSearch(searchTerm);
  };
  return (
    <CustomerCommonLayout>
      {/* search box */}
      <div className={` mb-12`}>
        <Link to="/" className="flex items-center py-6">
          <div className="ml-4 inline-block md:ml-8">
            <MdOutlineArrowBackIosNew className="h-4 w-4  text-[#000]" />
          </div>
          <h4 className=" ml-2 font-semibold">Resultado de {productToSearch}</h4>
        </Link>
        {result.length > 0 && (
          <>
            <section>
              <SingleShopProducts shopProducts={result} />
            </section>
          </>
        )}
      </div>
    </CustomerCommonLayout>
  );
};

export default SearchPage;
