import { ReactNode } from 'react';

interface IMKDrawer {
  children: ReactNode;
}
const MKDrawer = ({ children }: IMKDrawer) => {
  return (
    <div className="fixed left-0 top-0 z-10 h-screen w-full bg-[#00000069]">
      <div className="fixed right-0 top-0 flex h-full w-[80%] flex-col justify-between overflow-y-scroll bg-white shadow-sm md:w-3/12">
        {children}
      </div>
    </div>
  );
};

export default MKDrawer;
