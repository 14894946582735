import DeliveryAddress from './DeliveryAddress';
import DeliveryDate from './ScheduleStep/DeliveryDate';

const DeliveryStep = () => {
  return (
    <div className="w-full rounded-md p-5 pb-8 800px:w-[95%]">
      <section className="mb-2">
        <div className="mb-3">
          <h5 className="text-lg font-bold">Dirección de entrega</h5>
          <span className="text-sm text-gray-400">Selecciona una dirección de entrega</span>
        </div>
        <DeliveryAddress />
        <DeliveryDate />
      </section>
      <br />
    </div>
  );
};

export default DeliveryStep;
