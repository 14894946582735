// @ts-ignore
// @ts-nocheck
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrash } from 'react-icons/bs';

// import ProductDetailsCard from '../../ProductDetailsCard/ProductDetailsCard';
import { addTocart, removeFromCart, updateCart } from '../../../../redux/actions/cart';

import styles from '../../../../styles/styles';
import { parseDataToAddCart, parseToUpdateCart } from '../../../../utils/parseData/reduxCart';
import { MdOutlineHideImage } from 'react-icons/md';

const ProductInShopDetail = ({ data, shopId, shopDetails }) => {
  const { cart } = useSelector((state) => state.cart);
  const shopInCart = cart && cart[shopId];
  const itemInCart = shopInCart && Array.isArray(shopInCart.products) && shopInCart.products?.find((product) => product._id === data._id);
  const [quantity, setQuantity] = useState(itemInCart ? itemInCart.quantity : 0);

  const [clickAdd, setClickAdd] = useState(!!itemInCart);
  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const addToCartHandler = () => {
    if (quantity > 0 && itemInCart) {
      const cartData = parseToUpdateCart({
        id: shopId,
        shop: shopDetails,
        product: { ...data, quantity: quantity + 1 },
        cart: { ...cart },
      });

      dispatch(updateCart({ ...cartData }));
    }
    // setQuantity((prevValue) => prevValue + 1);
  };

  const subtractFromCartHandler = () => {
    if (quantity >= 1) {
      const cartData = parseToUpdateCart({
        id: shopId,
        shop: shopDetails,
        product: { ...data, quantity: quantity - 1 },
        cart: { ...cart },
      });
      dispatch(updateCart({ ...cartData }));
      // setQuantity((prevValue) => prevValue - 1);
      return;
    }
  };

  const removeProductHandler = () => {
    const removeShopProduct = { _id: shopId, productId: data._id };
    dispatch(removeFromCart(removeShopProduct));
    toast.success(`${data.name} eliminado del carrito`);
  };

  const handleClickAdd = () => {
    if (quantity === 0) {
      const cartData = parseDataToAddCart({ shopDetails, shop: shopDetails, id: shopId, product: data, cart: { ...cart } });
      dispatch(addTocart({ ...cartData }));
      toast.success(`${data.name} agregado al carrito`);
      setClickAdd(true);
    }
  };

  useEffect(() => {
    const shop = cart && cart[shopId];
    const product = shop && Array.isArray(shop.products) && shop.products.find((product) => product._id === data._id);
    if (product?.quantity >= 1) {
      setQuantity(product.quantity);
      setClickAdd(true);
      return;
    }
    if (!product && clickAdd) {
      setClickAdd(false);
      setQuantity(0);
    }
  }, [cart]);

  useEffect(() => {
    if (quantity >= 2 && itemInCart) {
      const cartData = parseToUpdateCart({ id: shopId, product: { ...data, quantity }, cart: { ...cart } });
      dispatch(updateCart({ ...cartData }));
    }
  }, [quantity]);

  return (
    <>
      <section className="flex w-56 flex-col justify-between rounded-lg border border-slate-200 bg-white p-3">
        <div className="h-[210px]">
          {Array.isArray(data.images) && data.images.length >= 1 ? (
            <img src={`${data.images && data.images[0]?.url}`} alt="" className="h-[120px] w-full object-contain" />
          ) : (
            <MdOutlineHideImage className="h-[120px] w-full text-gray-200" />
          )}

          <section className="px-2">
            <div className="flex items-center justify-between pt-2">
              <div className="">
                {typeof data.discountPrice === 'number' ? (
                  <>
                    <h5 className={`${styles.productDiscountPrice}`}>
                      C$
                      {data.discountPrice?.toFixed(2)}
                    </h5>
                    <h4 className={`${styles.price} text-black`}>{data.originalPrice ? 'C$' + data.originalPrice?.toFixed(2) : null}</h4>
                  </>
                ) : (
                  <h5 className={`${styles.productDiscountPrice}`}>
                    C$
                    {data.originalPrice?.toFixed(2)}
                  </h5>
                )}
              </div>
            </div>

            <h4 className="mb-2 line-clamp-1 font-[400] text-gray-600">{data.name}</h4>
          </section>
        </div>
        {!clickAdd && (
          <button onClick={handleClickAdd} className={`${styles.cart_button} p-1`}>
            Agregar
          </button>
        )}
        {clickAdd && itemInCart && (
          <section className="flex items-center justify-between">
            {quantity === 1 ? (
              <button className={`${styles.cart_delete_button}`} onClick={removeProductHandler}>
                <BsTrash color="white" />
              </button>
            ) : (
              <button className={`${styles.cart_decrement_button} text-xl`} onClick={subtractFromCartHandler}>
                <span className="self-center">-</span>
              </button>
            )}
            <span>{itemInCart.quantity}</span>
            <button className={`${styles.cart_add_button} text-xl`} onClick={addToCartHandler}>
              <span className="self-center">+</span>
            </button>
          </section>
        )}
      </section>
      {/* {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null} */}
    </>
  );
};

export default ProductInShopDetail;
