import { useAppSelector } from '../../../hooks/redux.hooks';
import { getShopProducts } from '../../../redux/slices/shopSlice';
import SingleShopProducts from '../../Common/SingleShopProducts';

const ShopProducts = () => {
  const shopProducts = useAppSelector((state) => getShopProducts(state.shops));

  return (
    <>
      <SingleShopProducts shopProducts={shopProducts} />
    </>
  );
};

export default ShopProducts;
