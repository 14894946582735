import Cookies from 'js-cookie';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { FaGoogle } from 'react-icons/fa';

import MKButton from '../../UI/MKButton';
import { SIGN_IN_WITH_GOOGLE } from '../../../services/auth/signin';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/redux.hooks';
import { loadUser } from '../../../redux/actions/user';

const GoogleLoginMethod = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const login = useGoogleLogin({
    onSuccess: (authResponse) => responseMessage(authResponse),
    onError: () => {},
  });
  const handleLogin = () => {
    login();
  };
  const responseMessage = async (response: TokenResponse) => {
    if (response?.access_token) {
      const result = await SIGN_IN_WITH_GOOGLE(response?.access_token);
      console.log('result google token:', result);

      if (result?.error) {
        toast.error(result.message ? result.message : 'Ha ocurrido un error');
        return;
      }
      Cookies.set('token', `${result.token}`);
      toast.success('Login Success!');
      navigate('/');
      dispatch(loadUser());
    }
  };

  const errorMessage = () => {};
  return (
    <MKButton className="w-full" onClick={handleLogin} variant="outlined">
      <div className="flex flex-row items-center justify-center gap-2">
        <FaGoogle className="text-primary-200" />
        <p className="font-bold text-primary-200">Continua con Google</p>
      </div>
    </MKButton>
  );
};

export default GoogleLoginMethod;
