import Lottie from 'react-lottie';
import animationData from '../../../Assests/animations/empty-orders.json';
interface IEmptyState {
  width?: number;
  height?: number;
  animationOptions?: IDefaultOptions;
}
interface IDefaultOptions {
  loop: boolean;
  autoplay: boolean;
}
const EmptyState = ({ width = 250, height = 250 }: IEmptyState) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={width} height={height} />
    </div>
  );
};

export default EmptyState;
