import { useNavigate } from 'react-router-dom';
import MKButton from '../../UI/MKButton';
import { useAppDispatch } from '../../../hooks/redux.hooks';
import { loadUser } from '../../../redux/actions/user';

const GuestMethod = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleClick = () => {
    navigate('/');
    dispatch(loadUser());
  };
  return (
    <MKButton onClick={handleClick} variant="outlined" className="my-4 w-full self-center border-red-400 md:w-2/4">
      <p className="text-center text-red-400">Ingresa como invitado</p>
    </MKButton>
  );
};

export default GuestMethod;
