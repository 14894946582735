import axios from 'axios';
import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import {
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProfilePage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  ChooseRegion,
} from './routes/Routes.js';
import { ShopPreviewPage } from './routes/ShopRoutes';

import { OrdersPage } from './routes/OrdersRoutes';

import { CategoriesPage } from './routes/ProductsRoutes';
import Store from './redux/store';
import { loadUser } from './redux/actions/user';
import ProtectedRoute from './routes/ProtectedRoute';
import { getAllProducts } from './redux/actions/product';
import { server } from './server';
import { getAllShopsAndProducts } from './redux/actions/shops';
import SearchPage from './pages/Search';

const App = () => {
  const [stripeApikey, setStripeApiKey] = useState('');
  async function getStripeApikey() {
    const { data } = await axios.get(`${server}/payment/stripeapikey`);
    setStripeApiKey(data.stripeApikey);
  }
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllShopsAndProducts());
    getStripeApikey();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {stripeApikey && (
          <Route
            path="/payment"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              </Elements>
            }
          />
        )}
        <Route path="/choose-region/:entityname" element={<ChooseRegion />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route path="/activation/:activation_token" element={<ActivationPage />} />
        <Route path="/products" element={<ProductsPage />} />

        {/* Categories */}
        <Route path="/categories" element={<CategoriesPage />} />
        {/* <Route path="/categories/:slug" element={<CategoriesPage />} /> */}

        <Route path="/best-selling" element={<BestSellingPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <ProtectedRoute>
              <UserInbox />
            </ProtectedRoute>
          }
        />

        {/* Orders */}
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <OrdersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/order/:id"
          element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/track/order/:id"
          element={
            <ProtectedRoute>
              <TrackOrderPage />
            </ProtectedRoute>
          }
        />
        {/* shop Routes */}

        <Route path="/shop/:id" element={<ShopPreviewPage />} />
        <Route
          path="/shop/:id/checkout"
          element={
            <ProtectedRoute>
              <CheckoutPage />
            </ProtectedRoute>
          }
        />
        <Route path="/order/success" element={<OrderSuccessPage />} />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
