import { useEffect } from 'react';
import { useCategoriesStore } from '../../store/categoriesStore';
import { getCategoriesService } from '../../services/categories/categories';

const useCategories = (lazyLoad?: false) => {
  const { categories, state, setCategories, setError, setLoading } = useCategoriesStore();

  const findCategories = async () => {
    const result = await getCategoriesService();
    setLoading();
    if (!result.success || !result.data) {
      setError();
      return;
    }
    setCategories(result.data);
  };
  useEffect(() => {
    if (!lazyLoad && categories === null) {
      findCategories();
    }
  }, []);

  return {
    categories,
    state,
  };
};

export default useCategories;
