import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShop } from '../../interfaces/shop';

interface InitialState {
  shops: IShop[];
}

const initialState: InitialState = {
  shops: [],
};

export const shopSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    setshopProducts: (state: InitialState, action: PayloadAction<IShop[]>) => {
      state.shops = action.payload;
    },
  },
});

export const getShopProducts = (state: InitialState) => state.shops;

export const { setshopProducts } = shopSlice.actions;
export default shopSlice.reducer;
