import { FaFacebook } from 'react-icons/fa';
import MKButton from '../../UI/MKButton';
import { SIGN_IN_WITH_FACEBOOK } from '../../../services/auth/signin';

const FacebookMethod = () => {
  const handleFBLogin = async () => {
    const data = await SIGN_IN_WITH_FACEBOOK();
    console.log('Login result:', data);
  };
  return (
    <MKButton className="w-full" variant="outlined" onClick={handleFBLogin}>
      <div className="flex flex-row items-center justify-center gap-2">
        <FaFacebook className="h-5 w-5 text-primary-200" />
        <p className="font-bold text-primary-200">Continuar con Facebook</p>
      </div>
    </MKButton>
  );
};

export default FacebookMethod;
