import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../../styles/styles';
import ProductDetailsCard from '../ProductDetailsCard/ProductDetailsCard';
import { MdOutlineHideImage } from 'react-icons/md';

const ProductCard = ({ data, shopId, isShopDetail = false }) => {
  const [open, setOpen] = useState(false);
  const imageUrl = data.images && data.images[0]?.url;
  return (
    <>
      <Link to={isShopDetail ? `/shop/product/${data._id}` : `/shop/${shopId}`} className="">
        <div className="h-[260px] cursor-pointer rounded-lg border border-gray-200 bg-white p-3">
          {imageUrl ? (
            <img src={imageUrl} alt="" className="h-[120px] w-full object-contain" />
          ) : (
            <MdOutlineHideImage className="h-[120px] w-full text-gray-200" />
          )}
          <section className="px-2">
            <div className="flex items-center justify-between py-2">
              <div>
                <h5 className={`${styles.productDiscountPrice}`}>
                  C$
                  {typeof data.discountPrice !== 'number' ? data.originalPrice : data.discountPrice}
                </h5>
                <h4 className={`${styles.price} text-b`}>{data.originalPrice ? 'C$' + data.originalPrice : '0'}</h4>
              </div>
            </div>

            <h4 className="line-clamp-2 pb-2 font-[400] text-gray-800">{data.name}</h4>
          </section>
        </div>
      </Link>
      {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
    </>
  );
};

export default ProductCard;
