import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { loading, isAuthenticated } = useSelector((state) => state.user);
  const [activeUser, setActiveUser] = useState(isAuthenticated);
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setActiveUser(isAuthenticated)
  }, [isAuthenticated])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])
  if (isLoading === false) {
    if (!activeUser) {
      return <Navigate to="/login" replace />;
    }
    return children;
  }
};

export default ProtectedRoute;
