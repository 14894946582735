import SingleProductcart from '../SingleProductCart';

interface IProductsInCart {
  shopId: string;
  products: any;
}
const ProductsInCart = ({ products, shopId }: IProductsInCart) => {
  return (
    <>
      {products.map((i, index) => (
        <>
          <SingleProductcart key={`cartidx-${i._id}`} data={i} shopId={shopId} />
        </>
      ))}
    </>
  );
};

export default ProductsInCart;
