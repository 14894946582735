import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { ICityResponse, IConfigurationState, IRegionsAndCity } from '../../interfaces/redux';

const initialState: IConfigurationState = {
  country: null,
  regions: null,
  selectedRegion: null,
  selectedCity: null,
  loading: false,
  error: false,
  loadingRegion: false,
};

export const configurationReducer = createReducer(initialState, {
  setCountrySuccess: (state: IConfigurationState, action: PayloadAction<string>) => {
    state.country = action.payload;
  },
  setRegionAction: (state: IConfigurationState, action: PayloadAction<IRegionsAndCity[]>) => {
    state.regions = action.payload;
  },
  setLoadingConfigAction: (state: IConfigurationState, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
  setErrorConfigAction: (state: IConfigurationState, action: PayloadAction<boolean>) => {
    state.error = action.payload;
    state.loading = false;
  },
  setConfigurationToInitialState: (state: IConfigurationState) => {
    state.country = null;
    state.regions = null;
  },
  setLoadingRegion: (state: IConfigurationState) => {
    state.loadingRegion = true;
  },
  setSelectedRegion: (state: IConfigurationState, action: PayloadAction<IRegionsAndCity>) => {
    state.selectedRegion = action.payload;
  },
  setSelectedCity: (state: IConfigurationState, action: PayloadAction<ICityResponse>) => {
    state.selectedCity = action.payload;
  },
});
