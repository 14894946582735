import { useState } from 'react';
import Header from '../components/Layout/Header/Header';
import CheckoutSteps from '../components/Checkout/CheckoutSteps';
import Checkout from '../components/Checkout/Checkout';
import Footer from '../components/Layout/Footer';
import PaymentInfo from '../components/Checkout/PaymentStep/PaymentInfo';
import OrderSummary from '../components/Checkout/SummaryStep/OrderSummary';
import { CHECKOUT_STEPS } from '../utils/constants/literalStrings';

const CheckoutPage = () => {
  const steps = ['delivery', 'payment', 'confirmation'];
  const [currentStep, setCurrentStep] = useState(1);
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const stepInfo = CHECKOUT_STEPS[steps[currentStep - 1]];

  return (
    <div>
      <Header />
      <br />
      <br />
      <section className="mx-auto my-0 max-w-[80rem]">
        <CheckoutSteps stepInfo={stepInfo} />
        {currentStep === 1 && <Checkout handleNextStep={handleNextStep} />}
        {currentStep === 2 && <PaymentInfo handleNextStep={handleNextStep} />}
        {currentStep === 3 && <OrderSummary />}
      </section>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default CheckoutPage;
