import { useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { BsCartPlus } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineHeart } from 'react-icons/ai';
import { removeFromWishlist } from '../../redux/actions/wishlist';
import styles from '../../styles/styles';

const Wishlist = ({ setOpenWishlist }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    setOpenWishlist(false);
  };

  return (
    <div className="fixed left-0 top-0 z-10 h-screen w-full bg-[#0000004b]">
      <div className="fixed right-0 top-0 flex h-full w-[80%] flex-col justify-between overflow-y-scroll bg-white shadow-sm 800px:w-[25%]">
        {wishlist && wishlist.length === 0 ? (
          <div className="flex h-screen w-full items-center justify-center">
            <div className="fixed right-3 top-3 flex w-full justify-end pr-5 pt-5">
              <RxCross1 size={25} className="cursor-pointer" onClick={() => setOpenWishlist(false)} />
            </div>
            <h5>La lista de favoritos está vacía!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pr-5 pt-5">
                <RxCross1 size={25} className="cursor-pointer" onClick={() => setOpenWishlist(false)} />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4`}>
                <AiOutlineHeart size={25} />
                <h5 className="pl-2 text-[20px] font-[500]">{wishlist && wishlist.length} items</h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {wishlist &&
                  wishlist.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                      addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value, setValue] = useState(1);
  const totalPrice = data.discountPrice * value;

  return (
    <div className="border-b p-4">
      <div className="w-full items-center 800px:flex">
        <RxCross1
          className="mb-2 ml-2 cursor-pointer 800px:mb-['unset'] 800px:ml-['unset']"
          onClick={() => removeFromWishlistHandler(data)}
        />
        <img src={`${data?.images[0]?.url}`} alt="" className="ml-2 mr-2 h-min w-[130px] rounded-[5px]" />

        <div className="pl-[5px]">
          <h1>{data.name}</h1>
          <h4 className="pt-3 font-Roboto text-[17px] font-[600] text-[#d02222] 800px:pt-[3px]">US${totalPrice}</h4>
        </div>
        <div>
          <BsCartPlus size={20} className="cursor-pointer" tile="Add to cart" onClick={() => addToCartHandler(data)} />
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
