import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateOrder } from '../../interfaces/order';

interface ICheckoutInitialState {
  checkoutForms: ICreateOrder;
  shopId: string;
}

const initialState: ICheckoutInitialState = {
  checkoutForms: {} as ICreateOrder,
  shopId: '',
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCheckoutInfo: (state: ICheckoutInitialState, action: PayloadAction<ICreateOrder>) => {
      state.checkoutForms = action.payload;
    },
    setCheckoutShopId: (state: ICheckoutInitialState, action: PayloadAction<string>) => {
      state.shopId = action.payload;
    },
  },
});

export const getCheckoutState = (state: ICheckoutInitialState) => state.checkoutForms;

export const { setCheckoutInfo, setCheckoutShopId } = checkoutSlice.actions;
export default checkoutSlice.reducer;
