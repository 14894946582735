import axios from 'axios';
import { server } from '../../server';

export const GET_COUNTRY_SERVICE = async () => {
  try {
    const { data } = await axios.get(`${server}/configuration/get-config`);

    return data.siteConfig;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};
