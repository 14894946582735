import { useEffect } from 'react';
import { getCountry, getCountryAndAllRegions } from '../../redux/actions/configuration';
import { useAppDispatch, useAppSelector } from '../redux.hooks';

interface IUseCountryAndRegion {
  refetch?: boolean;
}

export const useCountryAndRegion = (props?: IUseCountryAndRegion) => {
  const { country, regions, loading, selectedRegion, selectedCity, error } = useAppSelector((state) => state.configuration);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props?.refetch) {
      dispatch(getCountry());
      dispatch(getCountryAndAllRegions());
    }
  }, []);

  if (!loading && country === null) {
    dispatch(getCountry());
  }
  if (!loading && country !== null && !regions) {
    dispatch(getCountryAndAllRegions());
  }

  return { country, regions, loading, selectedRegion, selectedCity };
};
