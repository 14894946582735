import { useNavigate } from 'react-router-dom';
import styles from '../../../styles/styles';
import EmptyState from '../EmptyState';
import { MdAdd } from 'react-icons/md';

const CustomerEmptyAddress = () => {
  const navigate = useNavigate();
  const handleNavigateToAddress = () => {
    navigate('/profile', { state: { defaultStep: 7 } });
  };
  return (
    <div className="relative">
      {/* <CloseModal /> */}
      <section className="flex flex-col p-10 md:p-14">
        <EmptyState width={200} height={200} />
        <p className="text-center text-lg font-bold">Aún no has guardado ninguna dirección</p>

        <button onClick={handleNavigateToAddress} type="button" className={`${styles.button} mt-6 self-center`}>
          <MdAdd /> Agregar ahora
        </button>
      </section>
    </div>
  );
};

export default CustomerEmptyAddress;
