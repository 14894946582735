import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import CustomerCommonLayout from '../../components/Layout/CustomerCommonLayout';
import CustomerAllOrders from '../../components/Profile/AllOrders';
import styles from '../../styles/styles';

const OrdersPage = () => {
  return (
    <CustomerCommonLayout>
      <section>
        <div className={`${styles.section} mb-2`}>
          <Link to="/" className="flex items-center py-6">
            <div className="inline-block">
              <MdOutlineArrowBackIosNew className="h-4 w-4  text-[#000]" />
            </div>
            <h4 className=" ml-2 font-semibold">Ordenes</h4>
          </Link>
        </div>
        <CustomerAllOrders />
      </section>
    </CustomerCommonLayout>
  );
};

export default OrdersPage;
