// import ReactDOM from "react-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from 'redux-persist/integration/react';

import Store, {persistor} from "./redux/store";
import App from "./App";

ReactDOM.createRoot(document.getElementById('root')).render(
  <PersistGate loading={null} persistor={persistor}>
  <Provider store={Store}>
    <GoogleOAuthProvider clientId='1039685921166-u8qh1kv0ltqj8jpbnbj2pscjo8dhj2hm.apps.googleusercontent.com'>
      <App />
    </GoogleOAuthProvider>
  </Provider>
</PersistGate>
);

// reportWebVitals();
