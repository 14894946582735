import { createReducer, current } from "@reduxjs/toolkit";

const initialState = {
  cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
};

export const cartReducer = createReducer(initialState, {
  addToCart: (state, action) => {
    const shop = action.payload;
    const currentState = current(state)
    // const itemExist = state.cart.find((i) => i._id === shop._id);
    const shopExist = currentState.cart[shop._id];
    if (shopExist) {
      const product = shop?.products[0];
      const newShopProduct = {[shop._id]: {...shop, products: [...shopExist.products, {...product}]}}
      return {
        ...state,
        cart: {...state.cart, ...newShopProduct },
      };
    } 
    const newShopInCart = {[shop._id]: {...shop}}

    return {
      ...state,
      cart: {...state.cart, ...newShopInCart},
    };
    
  },

  updateQtyFromCart: (state, action) => {
    const shop = action.payload;
    const productToUpdate = shop?.products[0];
    const currentState = current(state)
    const shopInCart = {...currentState.cart[shop._id]};
   
    const productInCart = shopInCart?.products?.find(product => product._id === productToUpdate._id)

    if (!productInCart) return {
      ...state,
    };
    const update = shopInCart.products.map((cartProduct) => (cartProduct._id === productToUpdate._id ? productToUpdate : cartProduct))
    const updateShopProduct = {[shop._id]: {...shop, products: [...update]}}

    return {
      ...state,
      cart: {...state.cart, ...updateShopProduct},
    };
  },

  removeFromCart: (state, action) => {
    const shop = action.payload;
    const currentState = current(state)
    const shopInCart = currentState.cart[shop._id];
    const productInCart = shopInCart?.products?.find(product => product._id === action.payload.productId);

    if (!productInCart) return {
      ...state
    };
    const shouldRemoveShop = shopInCart.products.length <=1;
    if(shouldRemoveShop) {
      const cartCopy = {...state.cart};
      delete cartCopy[shop._id]
      return {
        ...state,
        cart: {...cartCopy},
      }
    }
    const filterProducts = shopInCart?.products?.filter(product => product._id !== shop.productId);
    if (filterProducts.length === 0)  {
      return {
        ...state
      }
    }
    const updateShopProduct = {[shop._id]: {...shop, products: [...filterProducts]}}

    return {
      ...state,
      cart: {...state.cart, ...updateShopProduct},
    };
  },
  clearShopCart: (state, action) => {
    const shop = action.payload;
    const currentState = current(state)
    const cartCopy = {...currentState.cart};
    delete cartCopy[shop._id];
      return {
        ...state,
        cart: {...state.cartCopy},
      };
  },
  clearAllShopCarts: (state, action) => {
    return {
      ...state,
      cart: []
    }
  }

});
