import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  productToSearch: string;
}

const initialState: InitialState = {
  productToSearch: '',
};

export const searchSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    setProductSearch: (state: InitialState, action: PayloadAction<string>) => {
      state.productToSearch = action.payload;
    },
  },
});

export const getSearchState = (state: InitialState) => state.productToSearch;

export const { setProductSearch } = searchSlice.actions;
export default searchSlice.reducer;
