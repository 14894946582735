import { styles } from '../styles';

interface IBoxDigitProps {
  code: string;
  index: number;
  pinCount: number;
  isInputBoxFocused: boolean;
}
const BoxDigit = ({ code, index, pinCount, isInputBoxFocused }: IBoxDigitProps) => {
  const emptyInput = '';
  const digit = code[index] || emptyInput;
  const isCurrentValue = index === code.length;
  const isLastValue = index === pinCount - 1;
  const isCodeComplete = code.length === pinCount;

  const isValueFocused = isCurrentValue || (isLastValue && isCodeComplete);

  const StyledSplitBoxes = isInputBoxFocused && isValueFocused ? styles.SplitBoxesFocused : styles.SplitBoxes;
  return (
    <section className={`${StyledSplitBoxes}`}>
      <p className={styles.SplitBoxText}>{digit}</p>
    </section>
  );
};

export default BoxDigit;
