import Lottie from 'react-lottie';
import animationData from '../../../Assests/animations/empty-orders.json';

export const EmptyOrders = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={250} height={250} />
    </div>
  );
};

export default EmptyOrders;
