import React from 'react';
import CheckoutSteps from '../components/Checkout/CheckoutSteps';
import Footer from '../components/Layout/Footer';
import Header from '../components/Layout/Header/Header';
import Payment from '../components/Payment/Payment';
import { CHECKOUT_STEPS } from '../utils/constants/literalStrings';

const PaymentPage = () => {
  const paymentStep = CHECKOUT_STEPS.payment;

  return (
    <div className="min-h-screen w-full bg-[#f6f9fc]">
      <Header />
      <br />
      <br />
      <CheckoutSteps stepInfo={paymentStep} />
      <Payment />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default PaymentPage;
