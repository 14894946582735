export const CONFIGURATION_ACTION_NAMES = {
  setLoadingConfigAction: 'setLoadingConfigAction',
  setErrorConfigAction: 'setErrorConfigAction',
  setCountryAction: 'setCountryAction',
  setRegionAction: 'setRegionAction',
  setLoadingRegion: 'setLoadingRegion',
  setConfigurationToInitialState: 'setConfigurationToInitialState',
  setCountrySuccess: 'setCountrySuccess',
  setSelectedRegion: 'setSelectedRegion',
  setSelectedCity: 'setSelectedCity',
};
