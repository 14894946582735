import { useState } from 'react';
import Loader from '../components/Layout/Loader';
import ProfileSideBar from '../components/Profile/ProfileSidebar';
import ProfileContent from '../components/Profile/ProfileContent';
import { useSelector } from 'react-redux';
import CustomerCommonLayout from '../components/Layout/CustomerCommonLayout';
import MKCard from '../components/UI/MKCard';
import { useLocation } from 'react-router-dom';

const ProfilePage = () => {
  const { loading } = useSelector((state) => state.user);
  const location = useLocation();
  const defaultStep = location?.state?.defaultStep || 1;
  const [active, setActive] = useState(defaultStep);
  console.log('location:', location?.state);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CustomerCommonLayout>
            <MKCard>
              <div className={`bg- flex py-10`}>
                <div className="sticky mt-[18%] w-[50px] 800px:mt-0 800px:w-[335px]">
                  <ProfileSideBar active={active} setActive={setActive} />
                </div>
                <ProfileContent active={active} />
              </div>
            </MKCard>
          </CustomerCommonLayout>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
