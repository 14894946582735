import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination } from 'swiper/modules';

import { banners } from '../../../static/data';
import styles from '../../../styles/styles';

const Banners = () => {
  return (
    <>
      <section className={`${styles.section} mb-12 `}>
        <Swiper
          slidesPerView={3}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          modules={[Pagination]}
        >
          {banners.map((banner) => (
            <SwiperSlide>
              <section className="relative mr-2">
                <img className="h-36 w-full rounded-xl object-cover" src={banner.image_Url} alt="banner" />
              </section>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  );
};

export default Banners;
