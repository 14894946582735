import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IMKCard {
  children: ReactNode;
  className?: string;
}
const MKCard: FC<IMKCard> = ({ children, className = '' }) => {
  return <section className={twMerge('rounded-xl border-2 border-slate-200 p-4', className)}>{children}</section>;
};

export default MKCard;
