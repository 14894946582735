import React, { useState } from 'react';
import MKButton from '../../../UI/MKButton';
import { MdOutlineLocationOn, MdClose, MdCheck } from 'react-icons/md';
import { IoIosArrowDown } from 'react-icons/io';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux.hooks';
import { getEmojiFlag } from 'countries-list';
import { ICityResponse } from '../../../../interfaces/redux';
import { setSelectedCity } from '../../../../redux/actions/configuration';
import { useNavigate } from 'react-router-dom';
import { COUNTRY_COMPOSITION } from '../../../../utils/constants/common';

const HeaderRegion = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedCity, selectedRegion, country } = useAppSelector((state) => state.configuration);
  const handleClickChangeRegion = () => {
    setIsModalOpen((prevValue) => !prevValue);
  };

  const handleSelectedCity = (city: ICityResponse) => {
    dispatch(setSelectedCity({ ...city }));
    setIsModalOpen(false);
  };
  const changeCityOrCountry = (changeType: string) => {
    navigate(`/choose-region/${changeType}`);
  };
  return (
    <section className="relative">
      <MKButton
        onClick={handleClickChangeRegion}
        variant="contained"
        className="ml-6 flex flex-row items-center rounded-xl bg-primary-100 px-4 py-2 text-primary-500"
      >
        <MdOutlineLocationOn className="h-5 w-5" />
        <p>{selectedCity ? selectedCity.name : 'Selecciona ciudad'}</p>
        <IoIosArrowDown className="h-5 w-5" />
      </MKButton>

      {isModalOpen && (
        <section className="absolute right-0 top-10 z-10 w-80 ">
          <div className="rounded-xl border border-gray-300 bg-white p-2 md:p-6">
            <div className="flex flex-row gap-2 ">
              <MKButton onClick={handleClickChangeRegion} variant="text" className="rounded-full bg-gray-300 p-2">
                <MdClose className="h-4 w-4" />
              </MKButton>
              <div>
                <p className="text-lg font-bold">Elige tu ciudad</p>
                <p className="text-xs font-thin">Selecciona una ciudad o puedes cambiar de pais</p>
              </div>
            </div>
            <section className="mt-2">
              {selectedCity && (
                <>
                  <p>Pais:</p>
                  <div className="my-2 flex flex-row items-center justify-between gap-2">
                    <div className="flex w-2/4 flex-row items-center justify-between gap-5 rounded-md border border-gray-300 px-2 py-2">
                      <p className="line-clamp-1 text-left">{country}</p>
                      <p className="">{getEmojiFlag('NI')}</p>
                    </div>
                    <MKButton
                      onClick={() => changeCityOrCountry(COUNTRY_COMPOSITION.country)}
                      variant="outlined"
                      className="w-2/5 rounded-lg py-2"
                    >
                      <p className=" font-bold text-primary-200">Cambiar</p>
                    </MKButton>
                  </div>
                  <p>Departamento:</p>

                  <div className="flex flex-row items-center justify-between gap-2">
                    <div className="flex w-2/4 flex-row items-center justify-between gap-5 rounded-md border border-gray-300 px-2 py-2">
                      <p className="line-clamp-1 text-left">{selectedRegion.name}</p>
                    </div>
                    <MKButton
                      onClick={() => changeCityOrCountry(COUNTRY_COMPOSITION.department)}
                      variant="outlined"
                      className="w-2/5 rounded-lg py-2"
                    >
                      <p className="line-clamp-1 font-bold text-primary-200">Cambiar</p>
                    </MKButton>
                  </div>
                </>
              )}
              <div className="my-5 border border-dashed border-gray-300" />

              <section>
                <p className="mb-3">Ciudad en donde deseas pedir</p>
                <div className="flex flex-col gap-2">
                  {selectedRegion.cities.map((city) =>
                    city.name === selectedCity.name ? (
                      <>
                        <section className="flex w-full flex-row items-center justify-between gap-2 rounded-lg border border-gray-300 bg-primary-100 p-2 text-primary-500">
                          {city.active && <p>{city.name}</p>}
                          <div className=" rounded-full bg-primary-500 p-1">
                            <MdCheck className="h-5 w-5 text-primary-100" />
                          </div>
                        </section>
                      </>
                    ) : (
                      <>
                        <MKButton
                          onClick={() => handleSelectedCity(city)}
                          variant="outlined"
                          className="w-full rounded-lg border border-gray-300 p-2"
                        >
                          {city.active && <p>{city.name}</p>}
                        </MKButton>
                      </>
                    )
                  )}
                </div>
              </section>
            </section>
          </div>
        </section>
      )}
    </section>
  );
};

export default HeaderRegion;
