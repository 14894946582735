export const APP_CONFIG = {
  country: 'Nicaragua',
  countryCode: '+505',
};

export const COUNTRY_COMPOSITION = {
  country: 'country',
  department: 'department',
  city: 'city',
};
