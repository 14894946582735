import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import DeliveryStep from './DeliveryStep';

import styles from '../../styles/styles';
import useMerkolineConfig from 'src/hooks/configuration/useMerkolineConfig';

const Checkout = ({ handleNextStep }) => {
  const { config, state } = useMerkolineConfig();
  const { cart } = useSelector((state) => state.cart);
  const [shippingPrice, setShippingPrice] = useState(config?.shipping_price || null);
  const { checkoutForms, shopId } = useSelector((state) => state.checkout);
  const { id } = useParams();
  const currentShopProducts = cart[id];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (config && !state.loading && shippingPrice === null) {
    setShippingPrice(config.shipping_price);
  }
  console.log('config:', config);
  console.log('state:', state);
  console.log('shippingPrice:', shippingPrice);

  const paymentSubmit = () => {
    if (shopId !== id && !checkoutForms?.deliveryTime) {
      toast.error('Por favor selecciona cuando entregaremos');
      return;
    }
    handleNextStep();
  };

  const subTotalPrice = currentShopProducts?.products?.reduce((acc, item) => acc + item.quantity * item.price, 0);

  const totalPrice = shippingPrice !== null ? (subTotalPrice + shippingPrice).toFixed(2) : '...';

  return (
    <div className="flex w-full flex-col items-center py-8">
      <div className="block w-[90%] 1000px:w-[70%]">
        <DeliveryStep />
      </div>
      <div className={`${styles.button} mt-10 w-[150px] bg-primary-200 800px:w-[280px]`} onClick={paymentSubmit}>
        <h5 className="text-white">Ir a pagar (C$ {totalPrice})</h5>
      </div>
    </div>
  );
};

export default Checkout;
