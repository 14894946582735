import React, { useRef, useState } from 'react';
import BoxDigit from './BoxDigit';
import { styles } from './styles';

interface MKOTPProps {
  pinCount?: number;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}
const MKOTP = ({ pinCount = 6, code, setCode }: MKOTPProps) => {
  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false);

  const boxArray = new Array(pinCount).fill(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOnBlur = () => {
    if (inputRef.current) {
      setIsInputBoxFocused(false);
    }
  };

  const handleOnPress = () => {
    if (inputRef.current) {
      setIsInputBoxFocused(true);
      inputRef.current.focus();
    }
  };

  return (
    <section className={styles.OTPInputContainer}>
      <section className={styles.SplitOTPBoxesContainer}>
        {boxArray.map((_, index) => (
          <BoxDigit code={code} index={index} pinCount={pinCount} isInputBoxFocused={isInputBoxFocused} />
        ))}
      </section>
      <input
        className={`${styles.TextInputHidden}`}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        maxLength={pinCount}
        ref={inputRef}
        onBlur={handleOnBlur}
      />
    </section>
  );
};

export default MKOTP;
