export const extractDateTimeComponents = (date: Date) =>
  date
    .toISOString()
    .split(/[^0-9]/)
    .slice(0, -1);

export const extractLocalTimeComponents = (date: Date) =>
  date
    .toTimeString()
    .split(/[^0-9]/)
    .slice(0, 3);

export const suffixAmPm = (h: number, minutes?: number | string): string =>
  `${h % 12 === 0 ? 12 : h % 12}${minutes ? `:${minutes}` : ''} ${h < 12 ? 'am' : 'pm'}`;
