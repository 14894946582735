import Lottie from 'react-lottie';
import animationData from '../Assests/animations/check-animation.json';
import CustomerCommonLayout from '../components/Layout/CustomerCommonLayout';
import { Link } from 'react-router-dom';

const OrderSuccessPage = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <CustomerCommonLayout>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="mb-14 text-center text-3xl text-gray-950">Gracias, hemos recibido tu orden</h5>
      <Link className="text-center" to="/">
        <p className="text-xl text-primary-500">Ir a inicio</p>
      </Link>
      <br />
    </CustomerCommonLayout>
  );
};

export default OrderSuccessPage;
