const SeparatorLine = () => {
  return (
    <div className="my-1 flex w-full flex-row items-center justify-between md:my-8">
      <div className="h-0.5 w-2/5 border border-gray-400" />
      <p className="text-xs font-bold text-gray-500">O</p>
      <div className="h-0.5 w-2/5 border border-gray-400" />
    </div>
  );
};

export default SeparatorLine;
