import { ButtonVariantType } from '../../types/common';

export const defaultButtonVariantStyle = (variant?: ButtonVariantType) => {
  const variantStyle = {
    outlined: 'border border-primary-200 rounded-2xl self-start px-2 py-3',
    contained: 'bg-primary-200 rounded-2xl self-start px-2 py-3',
    text: 'bg-transparent self-start px-2 py-3',
    default: 'bg-primary-200 rounded-2xl self-start px-2 py-3',
  };
  const selectedStyle = variantStyle[variant || 'default'];

  return selectedStyle;
};
