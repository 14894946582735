import { useState, useEffect } from 'react';
import { DAYS, MONTH_NAMES } from '../../../utils/constants/literalStrings';
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineCalendarToday } from 'react-icons/md';

interface IMKDatePicker {
  labelName?: string;
  containerClass?: string;
}
const MKDatePicker = ({ labelName = '', containerClass = '' }: IMKDatePicker) => {
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datepickerValue, setDatepickerValue] = useState('');
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [no_of_days, setNoOfDays] = useState([]);
  const [blankdays, setBlankdays] = useState([]);

  const initDate = () => {
    let today = new Date();
    setMonth(today.getMonth());
    setYear(today.getFullYear());
    // new Intl.DateTimeFormat('es', { day: '2-digit', month: 'short' }).format(today)
    setDatepickerValue(new Intl.DateTimeFormat('es', { weekday: 'long', day: '2-digit', month: 'short' }).format(today));
  };

  const isTodayOrSelected = (date) => {
    const today = new Date();
    const d = new Date(year, month, date);
    console.log('datepickerValue:', datepickerValue);
    console.log('today.toDateString():', today.toDateString());

    return today.toDateString() === d.toDateString() || d.toDateString() === datepickerValue;
  };

  const getDateValue = (date) => {
    const selectedDate = new Date(year, month, date);
    setDatepickerValue(new Intl.DateTimeFormat('es', { weekday: 'long', day: '2-digit', month: 'short' }).format(selectedDate));
    setShowDatepicker(false);
  };

  const getNoOfDays = () => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dayOfWeek = new Date(year, month).getDay();
    const blankdaysArray = Array.from({ length: dayOfWeek - 1 }, (_, i) => i + 1);
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    setBlankdays(blankdaysArray);
    setNoOfDays(daysArray);
  };

  const handleAddMonths = () => {
    setMonth(month + 1);
    getNoOfDays();
  };
  const handleSubMonths = () => {
    setMonth(month - 1);
    getNoOfDays();
  };

  useEffect(() => {
    initDate();
    getNoOfDays();
  }, []); // Run only once on mount

  return (
    <div className={containerClass}>
      <div className="container mx-auto py-1">
        <div className="mb-5 w-48 ">
          <label htmlFor="datepicker" className="mb-1 block font-bold text-gray-700">
            {labelName}
          </label>
          <div className="relative" onBlur={() => setShowDatepicker(false)}>
            <button className="focus:shadow-outline w-full rounded-lg" onClick={() => setShowDatepicker((prev) => !prev)}>
              <input
                type="hidden"
                name="date"
                ref={(el) =>
                  el && (el.value = year + '-' + ('0' + (month + 1)).slice(-2) + '-' + ('0' + new Date(year, month).getDate()).slice(-2))
                }
              />

              <input
                type="text"
                readOnly
                value={datepickerValue}
                onClick={() => getNoOfDays()}
                onKeyDown={(e) => e.key === 'Escape' && getNoOfDays()}
                className=" w-full rounded-lg border py-3 pl-4 pr-10 font-medium leading-none text-gray-600 shadow-sm focus:outline-none"
                placeholder="Select date"
              />

              <div className="absolute right-0 top-2 px-3 py-2">
                <MdOutlineCalendarToday className="text-gray-400" />
              </div>
            </button>

            {showDatepicker && (
              <div
                className="absolute left-0 top-0 mt-12 rounded-lg border-4 border-gray-500 bg-white p-4"
                style={{ width: '17rem' }}
                onClick={() => setShowDatepicker((prev) => !prev)}
                onMouseDown={(e) => e.preventDefault()} // prevent input from losing focus
              >
                <div className="mb-2 flex items-center justify-between">
                  <div>
                    <span className="text-lg font-bold text-gray-800">{MONTH_NAMES[month]}</span>
                    <span className="ml-1 text-lg font-normal text-gray-600">{year}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className={`inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-gray-200 ${
                        month === 0 ? 'cursor-not-allowed opacity-25' : ''
                      }`}
                      disabled={month === 0}
                      onClick={handleSubMonths}
                    >
                      <MdArrowBackIosNew />
                    </button>
                    <button
                      type="button"
                      className={`inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-gray-200 ${
                        month === 11 ? 'cursor-not-allowed opacity-25' : ''
                      }`}
                      disabled={month === 11}
                      onClick={handleAddMonths}
                    >
                      <MdArrowForwardIos />
                    </button>
                  </div>
                </div>

                <div className="-mx-1 mb-3 flex flex-wrap">
                  {Object.keys(DAYS).map((day, index) => (
                    <div key={index} style={{ width: '14.26%' }} className="px-1">
                      <div className="text-center text-xs font-medium text-gray-800">{day}</div>
                    </div>
                  ))}
                </div>

                <div className="-mx-1 flex flex-wrap">
                  {blankdays.map((blankday, index) => (
                    <div key={index} style={{ width: '14.28%' }} className="border border-transparent p-1 text-center text-sm"></div>
                  ))}
                  {no_of_days.map((date, dateIndex) => (
                    <div key={dateIndex} style={{ width: '14.28%' }} className="mb-1 px-1">
                      <div
                        onClick={() => getDateValue(date)}
                        className={`cursor-pointer rounded-full text-center text-sm leading-loose leading-none transition duration-100 ease-in-out ${
                          isTodayOrSelected(date) ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-200'
                        }`}
                      >
                        {date}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MKDatePicker;
