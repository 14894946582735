// @ts-nocheck
import React, { useCallback, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete';

import { mapStyles } from './mapStyle';
import { IMarker } from '../../../interfaces/common';
// import { changeCityLocation } from '../../../redux/actions/city';

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 12.1271712,
  lng: -86.267002,
};

const apiKey = process.env.REACT_APP_GOOGLE_MAPS;

interface IMapProps {
  height: string;
  width: string;
  coordenadas?: Omit<IMarker, 'placeDetails'> | null;
  radio?: number;
  handleGeolocation: (markerLocation: any) => void;
}

const getPlaceDetails = async (lat, lng) => {
  const results = await getGeocode({
    location: {
      lat,
      lng,
    },
  });
  const placeId = results[0].place_id;

  return placeId ? await getDetails({ placeId }) : null;
};

const libraries = ['places'];

export const MapApp = (props: IMapProps) => {
  const { height, width, coordenadas, handleGeolocation } = props;

  const mapContainerStyle = {
    height: height,
    width: width,
    borderStyle: '1px solid #FFFFFFF',
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [markers, setMarkers] = React.useState(
    coordenadas != undefined || coordenadas != null ? { lat: Number(coordenadas.lat), lng: Number(coordenadas.lng) } : null
  );

  const onMapClick = useCallback(
    (e) => {
      setMarkers({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
      getPlaceDetails(e.latLng.lat(), e.latLng.lng()).then((placeDetails) => {
        handleGeolocation({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          placeDetails,
        });
      });
      // dispatch(
      //   changeCityLocation({
      //     lat: e.latLng.lat(),
      //     lng: e.latLng.lng(),
      //   })
      // );
      // mapArr is to pass lat and lng destructured to the store
      // const [mapArr] = markers;
    },
    [markers]
  );

  const mapRef = useRef<google.maps.Map>();
  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return <p>Error</p>;
  if (!isLoaded) return <p>Loading...</p>;

  return (
    <div className="w-100 h-100 position-relative">
      {/* <Locate panTo={panTo} /> */}
      {/* <Search panTo={panTo} /> */}

      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={markers ? markers : center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {markers != null && (
          <Marker
            key={`${markers.lat}-${markers.lng}`}
            position={{ lat: markers.lat, lng: markers.lng }}
            // icon={{
            //   url: `/assets/mercoline-logo-300x300.png`,
            //   origin: new window.google.maps.Point(0, 0),
            //   // anchor: new window.google.maps.Point(15, 15),
            //   scaledSize: new window.google.maps.Size(50, 50),
            // }}
          />
        )}
      </GoogleMap>
    </div>
  );
};
