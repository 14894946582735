import React, { useState } from 'react';
import { MdMail } from 'react-icons/md';
import MKButton from '../../UI/MKButton';
import EmailDrawer from './EmailDrawer';

const EmailMethod = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleEmailDrawer = () => {
    setOpenDrawer((prevState) => !prevState);
  };

  return (
    <>
      <MKButton onClick={handleEmailDrawer} className="w-full" variant="outlined">
        <div className="flex flex-row items-center justify-center gap-2">
          <MdMail className="h-5 w-5 text-primary-200" />
          <p className=" font-bold text-primary-200">Continua con e-mail</p>
        </div>
      </MKButton>
      {openDrawer && <EmailDrawer handleEmailDrawer={handleEmailDrawer} />}
    </>
  );
};

export default EmailMethod;
