import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Signup from '../components/Signup/Signup';

const SignupPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);
  if (isAuthenticated === true) {
    navigate('/');
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="m-auto w-11/12 max-w-[40rem] px-2 px-4  md:w-2/4">
        <Signup />
      </div>
    </div>
  );
};

export default SignupPage;
