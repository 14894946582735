import { useState } from 'react';
import { getEmojiFlag } from 'countries-list';

import MKModal from '../../UI/MKModal';
import MKCard from '../../UI/MKCard';
import MKButton from '../../UI/MKButton';
import ChooseCity from './ChooseCity';

import { useCountryAndRegion } from '../../../hooks/configuration/location.hooks';
import { useAppDispatch } from '../../../hooks/redux.hooks';
import { IRegionsAndCity } from '../../../interfaces/redux';
import { setSelectedRegion } from '../../../redux/actions/configuration';

export const ChooseRegionModal = () => {
  const { country, regions, loading, selectedRegion } = useCountryAndRegion();
  const [selectCountry, setSelectCountry] = useState(false);
  const dispatch = useAppDispatch();

  const handleSelectRegion = (regionDetails: IRegionsAndCity) => {
    dispatch(setSelectedRegion({ ...regionDetails }));
  };

  return (
    <>
      {!loading && (
        <MKModal className="p-4 md:p-6">
          {!selectedRegion && (
            <section className="relative">
              {!selectCountry ? (
                <section className="mb-10">
                  <p className="text-xl font-bold">Hola,</p>
                  <p className="font-bold ">¿En donde quieres pedir?</p>

                  <MKButton
                    onClick={() => setSelectCountry(true)}
                    variant="text"
                    className="mt-4 w-full rounded-xl p-0 hover:bg-primary-100"
                  >
                    <MKCard className="flex flex-row items-center justify-between py-3">
                      <p>{country}</p>
                      <p className="text-2xl">{getEmojiFlag('NI')}</p>
                    </MKCard>
                  </MKButton>
                </section>
              ) : (
                <>
                  <p> {country}</p>
                  <p className="text-gray-500">Selecciona el departamento en donde quieres pedir</p>

                  <section className="mt-3">
                    {regions &&
                      regions.length > 0 &&
                      regions.map((region) => (
                        <MKButton
                          onClick={() => handleSelectRegion(region)}
                          key={`region-${region._id}`}
                          className="mb-3 w-full px-0 py-0"
                          variant="text"
                        >
                          <MKCard>
                            <p>{region.name}</p>
                          </MKCard>
                        </MKButton>
                      ))}
                  </section>
                </>
              )}
            </section>
          )}

          {regions && selectedRegion && <ChooseCity />}
        </MKModal>
      )}
    </>
  );
};
