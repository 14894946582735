import { REDUX_CART_ACTIONS } from "src/utils/constants/reduxLiterals";

// add to cart
export const addTocart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "addToCart",
    payload: data,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};

// update cart
export const updateCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "updateQtyFromCart",
    payload: data,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};

// remove from cart
export const removeFromCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "removeFromCart",
    payload: data,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};

// clear shop cart 
export const clearShopCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: REDUX_CART_ACTIONS.clearShopCart,
    payload: data
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data
}

// clear All Cart Items
export const clearAllShopCarts = (data) => async (dispatch, getState) => {
  dispatch({
    type: REDUX_CART_ACTIONS.clearAllShopCarts,
    payload: data
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data
}