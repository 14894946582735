import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CountDown from './CountDown';
import { addTocart } from '../../redux/actions/cart';
import styles from '../../styles/styles';

const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error('Item already in cart!');
    } else {
      if (data.stock < 1) {
        toast.error('Product stock limited!');
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success('Item added to cart successfully!');
      }
    }
  };
  return (
    <div className={`block w-full rounded-lg bg-white ${active ? 'unset' : 'mb-12'} p-2 lg:flex`}>
      <div className="lg:-w[50%] m-auto w-full">
        <img src={`${data.images[0]?.url}`} alt="" />
      </div>
      <div className="lg:[w-50%] flex w-full flex-col justify-center">
        <h2 className={`${styles.productTitle}`}>{data.name}</h2>
        <p>{data.description}</p>
        <div className="flex justify-between py-2">
          <div className="flex">
            <h5 className="pr-3 text-[18px] font-[500] text-[#d55b45] line-through">{data.originalPrice}$</h5>
            <h5 className="font-Roboto text-[20px] font-bold text-[#333]">{data.discountPrice}$</h5>
          </div>
          <span className="pr-3 text-[17px] font-[400] text-[#44a55e]">{data.sold_out} sold</span>
        </div>
        <CountDown data={data} />
        <br />
        <div className="flex items-center">
          <Link to={`/product/${data._id}?isEvent=true`}>
            <div className={`${styles.button} text-[#fff]`}>See Details</div>
          </Link>
          <div className={`${styles.button} ml-5 text-[#fff]`} onClick={() => addToCartHandler(data)}>
            Add to cart
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
