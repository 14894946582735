import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks';
import { setSelectedCity } from '../../../redux/actions/configuration';
import MKButton from '../../UI/MKButton';
import MKCard from '../../UI/MKCard';

const ChooseCity = () => {
  const { selectedRegion } = useAppSelector((state) => state.configuration);
  const dispatch = useAppDispatch();
  const handleSelectedCity = (city) => {
    dispatch(setSelectedCity({ ...city }));
  };
  return (
    <>
      <section>
        <p>{selectedRegion.name}</p>
        <p className="mb-3 text-gray-500">Selecciona la ciudad en donde quieres pedir</p>
        {Array.isArray(selectedRegion?.cities) && (
          <section>
            {selectedRegion?.cities.map((city) => (
              <MKButton onClick={() => handleSelectedCity(city)} variant="text" className="mb-3 w-full rounded-xl p-0 hover:bg-primary-200">
                <MKCard>
                  <p>{city.name}</p>
                </MKCard>
              </MKButton>
            ))}{' '}
          </section>
        )}
      </section>
    </>
  );
};

export default ChooseCity;
