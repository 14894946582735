import { MdArrowBackIosNew } from 'react-icons/md';
import { Link } from 'react-router-dom';

import MKCard from '../UI/MKCard';
import LoginNumberMethod from './NumberMethod';
import GoogleLoginMethod from './GoogleMethod';
import SeparatorLine from '../Common/SeparatorLine';
import FacebookMethod from './FacebookMethod';
import EmailMethod from './EmailMethod';
import GuestMethod from './GuestMethod';
import styles from '../../styles/styles';

const Login = () => {
  return (
    <MKCard className="m-auto w-5/6 max-w-[40rem] bg-white px-4 sm:px-10 md:w-2/4">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <section className="">
          <Link to={'/'} className="flex items-center text-gray-400">
            <MdArrowBackIosNew className="h-5 w-5 text-gray-400" />
            Ir a tienda
          </Link>
        </section>
      </div>
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-3 md:py-8">
          <section className="flex flex-col gap-3">
            <LoginNumberMethod />
            <SeparatorLine />
            <GoogleLoginMethod />
            <FacebookMethod />
            <EmailMethod />
            <SeparatorLine />
            <GuestMethod />
          </section>
        </div>
      </div>
      <div className={`${styles.noramlFlex} w-full`}>
        <h4>Aún no tienes una cuenta?</h4>
        <Link to="/sign-up" className="pl-2 text-primary-200 hover:text-primary-500">
          Registrate
        </Link>
      </div>
    </MKCard>
  );
};

export default Login;
