import axios, { AxiosResponse } from 'axios';
import { server } from '../../server';
import { ICategoryResponse } from '../../interfaces/categories';

export const getCategoriesService = async () => {
  try {
    const result: AxiosResponse<ICategoryResponse> = await axios.get(`${server}/category/get-all`);
    return {
      success: result.data?.success || false,
      data: result.data?.allCategories,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error al obtener categorias',
    };
  }
};
