// @ts-nocheck
import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoLocationOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckoutInfo } from '../../../../redux/slices/checkoutSlice';

const DeliveryAddress = () => {
  const { user } = useSelector((state) => state.user);
  const { checkoutForms } = useSelector((state) => state.checkout);
  const dispatch = useDispatch();

  const addresses = Array.isArray(user?.addresses) ? user.addresses : [];
  // ToDo: Validate which addresses are valid to fulfill a delivery;
  // const defaultAddress = checkoutForms?.shippingAddress
  //   ? checkoutForms.shippingAddress
  //   : addresses.find((address) => address.addressType === 'Default');
  // const [selected, setSelected] = useState(defaultAddress ? defaultAddress : null);
  const [selected, setSelected] = useState(null);

  const handleSelected = (address) => {
    setSelected(address);
    dispatch(setCheckoutInfo({ ...checkoutForms, shippingAddress: address }));
  };
  if (!checkoutForms?.shippingAddress && selected != null) {
    dispatch(setCheckoutInfo({ ...checkoutForms, shippingAddress: selected }));
  }

  return (
    <>
      {addresses?.map((adrress) => (
        <section
          onClick={() => handleSelected(adrress)}
          className={`h-26 mb-2 flex w-max cursor-pointer items-center rounded-xl border-2  p-2
          ${selected?._id === adrress._id ? 'border-primary-200' : 'border-slate-200'}`}
        >
          <IoLocationOutline className=" h-6 w-6" />
          <div className="w-36">
            <p className="text-sm">{adrress.address1}</p>
            <p className="line-clamp-2 pt-2 text-sm text-gray-500">{adrress.address2}</p>
          </div>
          {selected?._id === adrress._id && <FaCheck className="text-primary-500" />}
        </section>
      ))}
    </>
  );
};

export default DeliveryAddress;
