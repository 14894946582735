import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface IMKInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: JSX.Element;
}
const MKInput = ({ icon, type = 'text', className = '', ...props }: IMKInputProps) => {
  return (
    <section>
      {icon}
      <input className={twMerge('h-12 w-full rounded-xl border', className)} type={type} {...props}></input>
    </section>
  );
};

export default MKInput;
