import { useState, useEffect } from 'react';
import { useAppSelector } from './redux.hooks';
import { getSearchState } from '../redux/slices/searchSlice';

const useSearch = () => {
  const productToSearch = useAppSelector((state) => getSearchState(state.search));
  const [search, setSearch] = useState(productToSearch);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const { shops } = useAppSelector((state) => state.shops);

  const updateSearch = (newSearch: string) => {
    if (newSearch !== search) setSearch(newSearch);
  };

  useEffect(() => {
    if (search !== '') {
      const shopResult = [];
      shops.forEach((shop) => {
        const { products, ...shopInfo } = shop;
        const filter = products?.filter((product) => product.name?.toLowerCase().includes(search.toLowerCase()));

        if (filter.length > 0) {
          if (shopResult.length >= 0) {
            const searchExistingShop = shopResult.findIndex((shop) => shop._id === shopInfo._id);

            if (searchExistingShop !== -1) {
              // const updateProducts =
              shopResult[searchExistingShop] = { ...shopResult[searchExistingShop], products: [...shopResult[searchExistingShop], filter] };
              return;
            }
          }
          shopResult.push({ ...shopInfo, products: filter });
        }
      });

      if (shopResult.length > 0) {
        setResult([...shopResult]);
      }
      // filter product
    }
  }, [search]);

  return { updateSearch, search, loading, result };
};

export default useSearch;
