import { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RxAvatar } from 'react-icons/rx';
import axios from 'axios';
import Lottie from 'react-lottie';
import animationData from '../../Assests/animations/sign-up-aimation.json';

import { server } from '../../server';
import { MdArrowBackIosNew, MdKeyboardArrowDown } from 'react-icons/md';
import MKCard from '../UI/MKCard';
import styles from '../../styles/styles';
import MKInput from '../UI/MKInput';

const Singup = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [telephone, setTelephone] = useState('');
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(`${server}/user/create-user`, { name, email, password, avatar, phoneNumber: telephone })
      .then((res) => {
        toast.success(res.data.message);
        setName('');
        setEmail('');
        setPassword('');
        setAvatar();
        setTelephone('');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <MKCard className="w-full shadow-lg sm:px-4 lg:px-8">
      <Link to={'/'} className="flex items-center text-gray-400">
        <MdArrowBackIosNew className="h-5 w-5 text-gray-400" />
        Ir a tienda
      </Link>
      <Lottie options={defaultOptions} width={200} height={200} />

      <div className="flex flex-col justify-center">
        <div className="w-full sm:mx-auto sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Registrate en Merkoline</h2>
        </div>
        <div className="mt-8 w-full ">
          <div className="">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Nombre completo *
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="text"
                    autoComplete="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Teléfono *
                </label>
                <div className="mt-1">
                  <MKInput
                    className="bg-gray-200 pl-20 pr-3"
                    type="number"
                    placeholder="Número de teléfono"
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                    icon={
                      <div className="absolute mx-3">
                        <div className="my-2 flex flex-row items-center border-r-2 border-slate-300">
                          <img className="h-8" src="/assets/countryflag.png" alt="country logo" />
                          <MdKeyboardArrowDown className="h-5 w-5" />
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Correo electrónico *
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Contraseña *
                </label>
                <div className="relative mt-1">
                  <input
                    type={visible ? 'text' : 'password'}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                  {visible ? (
                    <AiOutlineEye className="absolute right-2 top-2 cursor-pointer" size={25} onClick={() => setVisible(false)} />
                  ) : (
                    <AiOutlineEyeInvisible className="absolute right-2 top-2 cursor-pointer" size={25} onClick={() => setVisible(true)} />
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="avatar" className="block text-sm font-medium text-gray-700"></label>
                <div className="mt-2 flex items-center">
                  <span className="inline-block h-8 w-8 overflow-hidden rounded-full">
                    {avatar ? (
                      <img src={avatar} alt="avatar" className="h-full w-full rounded-full object-cover" />
                    ) : (
                      <RxAvatar className="h-8 w-8" />
                    )}
                  </span>
                  <label
                    htmlFor="file-input"
                    className="ml-5 flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                  >
                    <span>Subir foto</span>
                    <input
                      type="file"
                      name="avatar"
                      id="file-input"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleFileInputChange}
                      className="sr-only"
                    />
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative flex h-[40px] w-full justify-center rounded-md border border-transparent bg-primary-200 px-4 py-2 text-sm font-medium text-white hover:bg-primary-100 hover:text-primary-500"
                >
                  Registrarme
                </button>
              </div>
              <div className={`${styles.noramlFlex} w-full`}>
                <h4>Ya tienes una cuenta?</h4>
                <Link to="/login" className="pl-2 text-primary-200">
                  Inicia sesión
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </MKCard>
  );
};

export default Singup;
