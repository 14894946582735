import axios from 'axios';
import { server } from '../../server';
import { APP_CONFIG } from '../../utils/constants/common';

export const SIGN_IN_WITH_PHONE_NUMBER = async (phoneNumber: string) => {
  try {
    const { data } = await axios.post(`${server}/user/login-with-number`, {
      phoneNumber: `${APP_CONFIG.countryCode}${phoneNumber}`,
    });
    return data;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};

export const VERIFY_OTP_CODE = async (otpCode: string, phoneNumber) => {
  try {
    const { data } = await axios.post(`${server}/user/verify-login-otp-code`, {
      phoneNumber: `${APP_CONFIG.countryCode}${phoneNumber}`,
      otpCode,
    });
    return data;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};
