import { Link } from 'react-router-dom';
import Categories from '../../components/Categories/CategoriesList';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import styles from '../../styles/styles';
import CustomerCommonLayout from '../../components/Layout/CustomerCommonLayout';

const CategoriesPage = () => {
  return (
    <>
      <CustomerCommonLayout>
        <section>
          <div className={`${styles.section} mb-12`}>
            <Link to="/" className="flex items-center py-6">
              <div className="inline-block">
                <MdOutlineArrowBackIosNew className="h-4 w-4  text-[#000]" />
              </div>
              <h4 className=" ml-2 font-semibold">Categorias</h4>
            </Link>
            <Categories />
          </div>
        </section>
      </CustomerCommonLayout>
    </>
  );
};

export default CategoriesPage;
