import { IoFilterSharp } from 'react-icons/io5';
import MKDatePicker from '../../UI/MKDatePicker';
import MKCard from '../../UI/MKCard';

const FilterOrders = () => {
  return (
    <section>
      <MKCard className="mt-2 max-h-min">
        <p className="">Filtrar</p>
        <div className="relative flex max-w-sm flex-row ">
          <MKDatePicker containerClass="mr-2" labelName="Desde" />
          <MKDatePicker labelName="Hasta" />
        </div>
        <button className="flex w-full items-center justify-center rounded-md border border-primary-200 p-2 text-primary-200">
          <IoFilterSharp className=" " /> Filtrar
        </button>
      </MKCard>
    </section>
  );
};

export default FilterOrders;
