import { LiaBroomSolid } from 'react-icons/lia';
import { useAppDispatch } from '../../../hooks/redux.hooks';
import { clearShopCart } from '../../../redux/actions/cart';

const CleanCart = ({ shopId }) => {
  const dispatch = useAppDispatch();
  const handleClearCart = () => {
    dispatch(clearShopCart(shopId));
  };

  return (
    <button className="flex items-center rounded-md border border-orange-400 p-1" onClick={handleClearCart}>
      <LiaBroomSolid className="text-orange-400" />
      <span className="text-orange-400">Limpiar</span>
    </button>
  );
};

export default CleanCart;
