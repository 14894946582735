import { useState } from 'react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { IoClose } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import MKDrawer from '../../../UI/MKDrawer';
import { useAppDispatch } from '../../../../hooks/redux.hooks';
import { loadUser } from '../../../../redux/actions/user';
import { SIGN_IN_WITH_EMAIL } from '../../../../services/auth/signin';
import styles from '../../../../styles/styles';

interface IEmailDrawerProps {
  handleEmailDrawer: () => void;
}

const EmailDrawer = ({ handleEmailDrawer }: IEmailDrawerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const result = await SIGN_IN_WITH_EMAIL({ email, password });

      if (!result || !result.success || !result?.token) {
        toast.error('Ha ocurrido un error');
        return;
      }
      Cookies.set('token', `${result.token}`);
      toast.success('Login Success!');
      navigate('/');
      dispatch(loadUser());
    } catch (error) {
      toast.error(error?.message || 'Es posible que tu email o correo no sean correctos');
    }
  };

  return (
    <MKDrawer>
      <section className="mx-4 mt-12">
        <button className="absolute right-4 top-1" onClick={handleEmailDrawer}>
          <IoClose className="h-8 w-8 hover:text-primary-500" />
        </button>
        <p className="mb-3 text-2xl">Ingresa tu e-mail para iniciar sesión </p>
        <form className=" space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Correo electrónico
            </label>
            <div className="mt-1">
              <input
                type="email"
                name="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Contraseña
            </label>
            <div className="relative mt-1">
              <input
                type={visible ? 'text' : 'password'}
                name="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              />
              {visible ? (
                <AiOutlineEye className="absolute right-2 top-2 cursor-pointer" size={25} onClick={() => setVisible(false)} />
              ) : (
                <AiOutlineEyeInvisible className="absolute right-2 top-2 cursor-pointer" size={25} onClick={() => setVisible(true)} />
              )}
            </div>
          </div>
          <div className={`${styles.noramlFlex} justify-between`}>
            <div className={`${styles.noramlFlex}`}>
              <input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Recordarme
              </label>
            </div>
            <div className="text-sm">
              <a href="/forgot-password" className="font-medium text-primary-200 hover:text-primary-500">
                Olvidaste tu contraseña?
              </a>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative flex h-[40px] w-full justify-center rounded-md border border-transparent bg-primary-200 px-4 py-2 text-sm font-medium text-white hover:bg-primary-500"
            >
              Iniciar sesión
            </button>
          </div>
          <div className={`${styles.noramlFlex} w-full`}>
            <h4>Aún no tienes una cuenta?</h4>
            <Link to="/sign-up" className="pl-2 text-primary-200 hover:text-primary-500">
              Registrate
            </Link>
          </div>
        </form>
      </section>
    </MKDrawer>
  );
};

export default EmailDrawer;
