import { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { setCheckoutInfo } from '../../../../../redux/slices/checkoutSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux.hooks';
import { extractDateTimeComponents, suffixAmPm } from '../../../../../utils/dates';

interface IDeliveryInterval {
  workHours: any[];
}
interface ISelectedInterval {
  intervalId: string;
  intervalDescription: string;
}
const DeliveryInterval = ({ workHours }: IDeliveryInterval) => {
  const [selectedInterval, setSelectedInterval] = useState<ISelectedInterval>();
  // @ts-ignore
  const { checkoutForms } = useAppSelector((state) => state.checkout);
  const dispatch = useAppDispatch();
  const getIntervalHours = workHours.map(({ interval, intervalId }) => {
    const startTime = extractDateTimeComponents(new Date(interval.start_time));
    const startTimeHour = suffixAmPm(Number(startTime[3]), startTime[4]);
    const endTime = extractDateTimeComponents(new Date(interval.end_time));
    const endTimeHour = suffixAmPm(Number(endTime[3]), endTime[4]);

    return {
      startTime: startTimeHour,
      endTime: endTimeHour,
      intervalId,
    };
  });
  const handleSelectedInterval = (intervalId: string, intervalDescription: string) => {
    setSelectedInterval({ intervalId, intervalDescription });
    dispatch(setCheckoutInfo({ ...checkoutForms, deliveryTime: { intervalId, intervalDescription } }));
  };

  return (
    <section className="mt-4">
      <p className="mb-2 text-sm text-gray-400">¿En que periodo lo entregamos?</p>

      <div className="flex flex-row gap-2">
        {getIntervalHours.map((interval) => (
          <button
            onClick={() => handleSelectedInterval(interval.intervalId, `${interval.startTime} - ${interval.endTime}`)}
            className={`flex items-center rounded-xl border-2 border-gray-200 px-4 py-2 ${
              selectedInterval?.intervalId === interval.intervalId && 'border-primary-200'
            }`}
          >
            <div className="flex flex-col items-center">
              <span>{interval.startTime}</span>
              <span>{interval.endTime}</span>
            </div>
            {selectedInterval?.intervalId === interval.intervalId && <FaCheck className="ml-2 text-primary-500" />}
          </button>
        ))}
      </div>
    </section>
  );
};

export default DeliveryInterval;
