import React, { useEffect } from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';
import { twMerge } from 'tailwind-merge';

interface IMKModalProps {
  children?: React.ReactNode;
  className?: string;
  onClickBackdrop?: () => void;
}

const MKModal: React.FC<IMKModalProps> = ({ children, onClickBackdrop, className }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="fixed inset-0 z-[100] flex max-h-screen flex-col items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-gray-900 opacity-60" onClick={onClickBackdrop} />
      <div
        className={twMerge('z-50  mx-2 w-full rounded-lg border border-gray-100 bg-white shadow-lg md:mx-0 md:w-3/5 lg:w-2/5', className)}
      >
        {children}
      </div>
    </div>
  );
};

interface ICloseModalProps {
  onClick?: () => void;
  className?: string;
}

const CloseModal: React.FC<ICloseModalProps> = ({ onClick, className }) => {
  return (
    <button onClick={onClick} aria-label="Close Modal" className={twMerge('absolute right-3 top-3 p-2', className)}>
      <RiCloseCircleFill className="h-7 w-7 text-gray-500" />
    </button>
  );
};

export { CloseModal };
export default MKModal;
