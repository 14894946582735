import { toast } from 'react-toastify';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

import styles from '../../../../styles/styles';

const CardMethod = () => {
  // const stripe = useStripe();

  const paymentHandler = async (e) => {
    e.preventDefault();
    try {
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // };
      // const { data } = await axios.post(`${server}/payment/process`, paymentData, config);
      // const client_secret = data.client_secret;
      // if (!stripe || !elements) return;
      // const result = await stripe.confirmCardPayment(client_secret, {
      //   payment_method: {
      //     card: elements.getElement(CardNumberElement),
      //   },
      // });
      // if (result.error) {
      //   toast.error(result.error.message);
      // } else {
      //   if (result.paymentIntent.status === 'succeeded') {
      //     order.paymnentInfo = {
      //       id: result.paymentIntent.id,
      //       status: result.paymentIntent.status,
      //       type: 'Credit Card',
      //     };
      //     await axios.post(`${server}/order/create-order`, order, config).then((res) => {
      //       setOpen(false);
      //       navigate('/order/success');
      //       toast.success('Order successful!');
      //       localStorage.setItem('cartItems', JSON.stringify([]));
      //       localStorage.setItem('latestOrder', JSON.stringify([]));
      //       window.location.reload();
      //     });
      //   }
      // }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <section>
      <div className="flex w-full">
        <form className="w-full" onSubmit={paymentHandler}>
          <div className="flex w-full pb-3">
            <div className="w-[50%]">
              <label className="block pb-2">Name On Card</label>
              <input required placeholder="Nombre" className={`${styles.input} !w-[95%] text-[#444]`} value="" />
            </div>
            <div className="w-[50%]">
              <label className="block pb-2">Exp Date</label>
              <CardExpiryElement
                className={`${styles.input}`}
                options={{
                  style: {
                    base: {
                      fontSize: '19px',
                      lineHeight: '1.5',
                      color: '#444',
                    },
                    empty: {
                      color: '#3a120a',
                      backgroundColor: 'transparent',
                      '::placeholder': {
                        color: '#444',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>

          <div className="flex w-full pb-3">
            <div className="w-[50%]">
              <label className="block pb-2">Card Number</label>
              <CardNumberElement
                className={`${styles.input} !h-[35px] !w-[95%]`}
                options={{
                  style: {
                    base: {
                      fontSize: '19px',
                      lineHeight: '1.5',
                      color: '#444',
                    },
                    empty: {
                      color: '#3a120a',
                      backgroundColor: 'transparent',
                      '::placeholder': {
                        color: '#444',
                      },
                    },
                  },
                }}
              />
            </div>
            <div className="w-[50%]">
              <label className="block pb-2">CVV</label>
              <CardCvcElement
                className={`${styles.input} !h-[35px]`}
                options={{
                  style: {
                    base: {
                      fontSize: '19px',
                      lineHeight: ' 1.5',
                      color: '#444',
                    },
                    empty: {
                      color: '#3a120a',
                      backgroundColor: 'transparent',
                      '::placeholder': {
                        color: '#444',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <input
            type="submit"
            value="Submit"
            className={`${styles.button} h-[45px] cursor-pointer rounded-[5px] !bg-[#f63b60] text-[18px] font-[600] text-[#fff]`}
          />
        </form>
      </div>
    </section>
  );
};

export default CardMethod;
