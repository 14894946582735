import { create } from 'zustand';
import { AppConfiguration } from '../interfaces/config';
import { FetchState } from '../interfaces/common';

interface UseConfigsStore {
  config: AppConfiguration | null;
  state: FetchState;
  setConfig: (newConfig: AppConfiguration) => void;
  setLoading: (loading?: boolean) => void;
  setError: (error?: boolean, message?: string) => void;
  clearState: () => void;
}

const initialRequestState = { loading: false, error: false, message: '' };

const useConfigStore = create<UseConfigsStore>((set) => ({
  config: null,
  state: { ...initialRequestState },
  setConfig: (newConfig) => set(() => ({ config: newConfig, state: { ...initialRequestState } })),
  setLoading: (loading = true) => set({ state: { loading: loading, error: false, message: '' } }),
  setError: (error = true, message = '') => set({ state: { loading: false, error: error, message } }),
  clearState: () => set({ state: { loading: false, error: false, message: null } }),
}));

export default useConfigStore;
