import React, { useState } from 'react';
import styles from '../../../../../styles/styles';
import { RxCross1 } from 'react-icons/rx';
import { MapApp } from '../../../../UI/GoogleMap';
import MKInput from '../../../../UI/MKInput';
import { IMarker } from '../../../../../interfaces/common';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../../../hooks/redux.hooks';
import { updatUserAddress } from '../../../../../redux/actions/user';

interface IEditAddressProps {
  handleClose: () => void;
  isEdit?: boolean;
  addressDetails?: any;
}
const EditAddress = ({ handleClose, isEdit = false, addressDetails }: IEditAddressProps) => {
  const [zipCode, setZipCode] = useState('');
  const [address1, setAddress1] = useState(addressDetails?.address1 || '');
  const [address2, setAddress2] = useState(addressDetails?.address2 || '');
  const [geolocation, setGeolocation] = useState<IMarker>(addressDetails?.coordinates || null);
  const [addressType, setAddressType] = useState(addressDetails?.addressType || '');
  const dispatch = useAppDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (addressType === '' || address1 === '' || address2 === '' || !geolocation) {
      toast.error('Por favor llena los campos requeridos');
      return;
    }
    const country = geolocation.placeDetails.address_components[5].long_name;
    const city = geolocation.placeDetails.address_components[4].long_name;
    const coordinates = { latitude: geolocation.lat, longitude: geolocation.lng };

    dispatch(updatUserAddress(country, city, address1, address2, zipCode, addressType, coordinates, addressDetails?._id || null));

    handleClose();
  };

  const handleGeolocation = (markerLocation) => {
    const neighborhood = Array.isArray(markerLocation?.placeDetails?.address_components)
      ? markerLocation?.placeDetails?.address_components[1].long_name
      : '';
    setAddress1(neighborhood);
    setGeolocation(markerLocation);
  };

  const addressTypeData = [
    {
      name: 'Casa',
    },
    {
      name: 'Oficina',
    },
    {
      name: 'Otro',
    },
  ];

  return (
    <section>
      <div className="fixed left-0 top-0 flex h-screen w-full items-center justify-center bg-[#0000004b] ">
        <div className="relative h-5/6 w-full overflow-y-scroll rounded-xl bg-white shadow md:h-2/3 md:w-2/3 lg:w-2/5">
          <section className="mb-2 flex w-full items-center justify-between p-4">
            <h1 className="w-full text-base md:text-2xl"> {addressDetails ? 'Editar dirección' : 'Agregar nueva dirección de entrega'}</h1>
            <RxCross1 size={25} className="cursor-pointer" onClick={() => handleClose()} />
          </section>
          <div className="w-full">
            <p className="mb-2 px-4">Ubica el pin en tu dirección exacta en el mapa</p>

            <MapApp
              handleGeolocation={handleGeolocation}
              width="100%"
              height="30vh"
              coordenadas={
                addressDetails?.coordinates
                  ? { lat: addressDetails?.coordinates.latitude, lng: addressDetails?.coordinates.longitude }
                  : null
              }
            />
            <form onSubmit={handleSubmit} className="w-full">
              <div className="block w-full p-4">
                <div className="w-full pb-2">
                  <p className="block pb-2 text-gray-600">Barrio / Sector *</p>
                  <MKInput type="address" className={`${styles.input}`} value={address1} placeholder="ej. Barrio Rubén Darío" disabled />
                </div>
                <div className="w-full pb-2">
                  <p className="block pb-2 text-gray-600">Dirección *</p>
                  <MKInput
                    type="address"
                    className={`${styles.input}`}
                    required
                    value={address2}
                    placeholder="Agrega tu dirección"
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </div>

                <div className="w-full pb-2">
                  <p className="block pb-2 text-gray-600">Nombre de la dirección </p>
                  <select
                    name=""
                    id=""
                    value={addressType}
                    onChange={(e) => setAddressType(e.target.value)}
                    className="w-full rounded-xl border bg-gray-100 px-4 py-3 text-gray-500"
                  >
                    <option value="" className="block border pb-2 text-gray-500">
                      Elige el tipo de dirección
                    </option>
                    {addressTypeData &&
                      addressTypeData.map((item) => (
                        <option className="block pb-2" key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className=" flex w-full justify-center pb-2">
                  <button type="submit" className={`${styles.button} mt-5 cursor-pointer`}>
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditAddress;
