import { useEffect } from 'react';
import { getConfigService } from '../../services/configuration/appConfig';
import useConfigStore from '../../store/merkolineConfig';

const useMerkolineConfig = (lazyLoad?: false) => {
  const { config, state, setError, setLoading, setConfig } = useConfigStore();

  const retrieveConfig = async () => {
    setLoading(true);
    const result = await getConfigService();
    if (result.success === false || !result.data) {
      setError(true, result.message || 'Error al obtener configuración');
      return;
    }
    setConfig(result.data);
  };

  useEffect(() => {
    if (!lazyLoad) {
      // retrieve data
      retrieveConfig();
    }
  }, []);

  return {
    config: config,
    state,
  };
};

export default useMerkolineConfig;
