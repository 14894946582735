import React, { useEffect, useState } from 'react';
import { BsFillBagFill } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles/styles';
import { getAllOrdersOfUser } from '../redux/actions/order';
import { server } from '../server';
import { RxCross1 } from 'react-icons/rx';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MdOutlineHideImage } from 'react-icons/md';

const UserOrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const data = orders && orders.find((item) => item._id === id);

  const reviewHandler = async (e) => {
    await axios
      .put(
        `${server}/product/create-new-review`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
        setComment('');
        setRating(null);
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const refundHandler = async () => {
    await axios
      .put(`${server}/order/order-refund/${id}`, {
        status: 'Processing refund',
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className={`min-h-screen py-4 ${styles.section}`}>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="pl-2 text-[25px]">Order Details</h1>
        </div>
      </div>

      <div className="flex w-full items-center justify-between pt-6">
        <h5 className="text-[#00000084]">
          Order ID: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="text-[#00000084]">
          Placed on: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      {/* order items */}
      <br />
      <br />
      {data &&
        data?.cart.map((item, index) => {
          return (
            <div className="mb-5 flex w-full items-start">
              {Array.isArray(item?.images) && item?.images.length > 0 && item?.images[0].url ? (
                <img src={`${item?.images[0]?.url}`} alt="" className="h-[80px] w-[80px]" />
              ) : (
                <MdOutlineHideImage className="h-20 w-20" />
              )}
              <div className="w-full">
                <h5 className="pl-3 text-[20px]">{item.name}</h5>
                <h5 className="pl-3 text-[20px] text-[#00000091]">
                  US${item.discountPrice} x {item.qty}
                </h5>
              </div>
              {!item.isReviewed && data?.status === 'Delivered' ? (
                <div className={`${styles.button} text-[#fff]`} onClick={() => setOpen(true) || setSelectedItem(item)}>
                  Write a review
                </div>
              ) : null}
            </div>
          );
        })}

      {/* review popup */}
      {open && (
        <div className="fixed left-0 top-0 z-50 flex h-screen w-full items-center justify-center bg-[#0005]">
          <div className="h-min w-[50%] rounded-md bg-[#fff] p-3 shadow">
            <div className="flex w-full justify-end p-3">
              <RxCross1 size={30} onClick={() => setOpen(false)} className="cursor-pointer" />
            </div>
            <h2 className="text-center text-[30px] font-[500]">Give a Review</h2>
            <br />
            <div className="flex w-full">
              {/* {Array.isArray(selectedItem?.images) && selectedItem?.images[0]?.url ? (
                <img src={`${selectedItem?.images[0]?.url}`} alt="" className="h-[80px] w-[80px]" />
              ) : (
                <MdOutlineHideImage />
              )} */}
              <div>
                <div className="pl-3 text-[20px]">{selectedItem?.name}</div>
                <h4 className="pl-3 text-[20px]">
                  US${selectedItem?.discountPrice} x {selectedItem?.qty}
                </h4>
              </div>
            </div>

            <br />
            <br />

            {/* ratings */}
            <h5 className="pl-3 text-[20px] font-[500]">
              Give a Rating <span className="text-red-500">*</span>
            </h5>
            <div className="ml-2 flex w-full pt-1">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar key={i} className="mr-1 cursor-pointer" color="rgb(246,186,0)" size={25} onClick={() => setRating(i)} />
                ) : (
                  <AiOutlineStar key={i} className="mr-1 cursor-pointer" color="rgb(246,186,0)" size={25} onClick={() => setRating(i)} />
                )
              )}
            </div>
            <br />
            <div className="ml-3 w-full">
              <label className="block text-[20px] font-[500]">
                Write a comment
                <span className="ml-1 text-[16px] font-[400] text-[#00000052]">(optional)</span>
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="How was your product? write your expresion about it!"
                className="mt-2 w-[95%] border p-2 outline-none"
              ></textarea>
            </div>
            <div className={`${styles.button} ml-3 text-[20px] text-white`} onClick={rating > 1 ? reviewHandler : null}>
              Submit
            </div>
          </div>
        </div>
      )}

      <div className="w-full border-t text-right">
        <h5 className="pt-3 text-[18px]">
          Total Price: <strong>US${data?.totalPrice}</strong>
        </h5>
      </div>
      <br />
      <br />
      <div className="w-full items-center 800px:flex">
        <div className="w-full 800px:w-[60%]">
          <h4 className="pt-3 text-[20px] font-[600]">Shipping Address:</h4>
          <h4 className="pt-3 text-[20px]">{data?.shippingAddress.address1 + ' ' + data?.shippingAddress.address2}</h4>
          <h4 className=" text-[20px]">{data?.shippingAddress.country}</h4>
          <h4 className=" text-[20px]">{data?.shippingAddress.city}</h4>
          <h4 className=" text-[20px]">{data?.user?.phoneNumber}</h4>
        </div>
        <div className="w-full 800px:w-[40%]">
          <h4 className="pt-3 text-[20px]">Payment Info:</h4>
          <h4>Status: {data?.paymentInfo?.status ? data?.paymentInfo?.status : 'Not Paid'}</h4>
          <br />
          {data?.status === 'Delivered' && (
            <div className={`${styles.button} text-white`} onClick={refundHandler}>
              Give a Refund
            </div>
          )}
        </div>
      </div>
      <br />
      <Link to="/">
        <div className={`${styles.button} text-white`}>Send Message</div>
      </Link>
      <br />
      <br />
    </div>
  );
};

export default UserOrderDetails;
