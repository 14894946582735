import { IGoogleAuthUserInfoResponse } from '../../interfaces/auth';

export const ParseGoogleResponse = ({ given_name, email, family_name, picture }: IGoogleAuthUserInfoResponse) => {
  return {
    email,
    firstName: given_name,
    lastName: family_name,
    avatar: picture,
  };
};
