import { ReactNode } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer';
interface ICustomerCommonLayout {
  children: ReactNode;
}
const CustomerCommonLayout = ({ children }: ICustomerCommonLayout) => {
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <div className="mb-12">
        <Header />
        <div className="mx-auto my-0 max-w-[80rem]">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default CustomerCommonLayout;
