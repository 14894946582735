import { IParseDataAddToCart } from '../../interfaces/redux';

export const parseDataToAddCart = ({ id, product, cart, shop }: IParseDataAddToCart) => {
  const shopInCart = cart[id];
  const productExist = shopInCart?.products?.find((productInCart) => productInCart._id === product._id);
  if (productExist) return;
  const productPrice = product.discountPrice || product.originalPrice;
  const productToAdd = {
    _id: product._id,
    quantity: 1,
    name: product.name,
    price: productPrice,
    images: product.images,
    originalPrice: product.originalPrice,
  };
  return { _id: id, products: [productToAdd], shop: shop };
};

export const parseToUpdateCart = ({ id, product, cart }: Omit<IParseDataAddToCart, 'shop'>) => {
  const shopInCart = cart[id];
  const productExist = shopInCart?.products?.find((productInCart) => productInCart._id === product._id);
  if (!shopInCart && productExist) return;
  const shopProduct = { ...shopInCart, products: [{ ...productExist, quantity: product.quantity }] };
  return shopProduct;
};
